import styled, { css, DefaultTheme } from 'styled-components';

import type { SpacingValue, PaddingProps } from './Box';

const findBreakpoint = (theme: DefaultTheme, key: string): string | undefined => {
    return Object.keys(theme.breakpoints).find(
        bKey => bKey === key || theme.breakpoints[bKey].shortName === key
    );
};

const generatePadding = (
    padding: SpacingValue | PaddingProps,
    properties: string[],
    theme: DefaultTheme
) => {
    if (typeof padding === 'number') {
        return properties.map(
            property => css`
                ${property}: ${theme.spacing * padding}rem;
            `
        );
    } else {
        const keys = Object.keys(padding);

        return keys.map(key => {
            const breakpointName = findBreakpoint(theme, key);

            if (!breakpointName) return null;

            return css`
                @media ${theme.breakpoints[breakpointName].media} {
                    ${properties.map(
                        property => css`
                            ${property}: ${theme.spacing * padding[key]}rem;
                        `
                    )}
                }
            `;
        });
    }
};

export const StyledBox = styled.div<{ $padding: SpacingValue | PaddingProps }>`
    ${({ theme, $padding }) => {
        const keys = Object.keys($padding);

        if (typeof $padding === 'number' || keys.some(key => findBreakpoint(theme, key))) {
            return generatePadding($padding, ['padding'], theme);
        } else {
            return keys.map(key => {
                const paddingValue = $padding[key] as SpacingValue;

                switch (key) {
                    case 'x':
                        return generatePadding(
                            paddingValue,
                            ['padding-left', 'padding-right'],
                            theme
                        );
                    case 'y':
                        return generatePadding(
                            paddingValue,
                            ['padding-top', 'padding-bottom'],
                            theme
                        );
                    default:
                        return generatePadding(paddingValue, [`padding-${key}`], theme);
                }
            });
        }
    }}
`;
