import styled, { css } from 'styled-components';

import { ArrowIcon, Container as ButtonContainer } from 'Elements/Button/Arrow/Arrow.styled';
import { animated } from '@react-spring/web';

import Image from 'Elements/Image';

export const OrientationIcon = styled(Image)`
    ${({ theme }) => css`
        width: 2.9rem;
        margin-bottom: 1.5rem;

        @media ${theme.breakpoints.medium.media} {
            display: none;
        }
    `}
`;

export const Background = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
`;

export const Button = styled.button`
    ${({ theme }) => css`
        top: 16.5rem;
        right: -1rem;
        border: none;
        background: transparent;
        position: absolute;
        display: inline-block;

        @media ${theme.breakpoints.small.media} {
            top: 14rem;
        }

        @media ${theme.breakpoints.smallLarge.media} {
            top: 9rem;
        }

        @media ${theme.breakpoints.medium.media} {
            top: 4rem;
        }

        @media ${theme.breakpoints.large.media} {
            top: 3rem;
        }
    `}
`;

export const Wrapper = styled.div`
    ${({ theme }) => css`
        position: relative;
        margin: 1rem 0;

        @media ${theme.breakpoints.medium.media} {
            margin: 2rem 0 2rem;
        }

        @media ${theme.breakpoints.large.media} {
            margin: 2rem 0 7rem;
        }
    `}
`;

export const Embed = styled.iframe`
    height: 46rem;
    width: 100%;
    border: 0;
`;

export const EmbedWrapper = styled(animated.div)`
    ${({ theme }) => css`
        position: relative;
        width: 100%;

        @media ${theme.breakpoints.large.media} {
            margin-top: -3.5rem;
        }
    `}
`;

export const CloseIcon = styled.svg`
    ${({ theme }) => css`
        height: 2.5rem;
        width: 2.5rem;
        color: white;
        background-color: ${theme.colors.tertiary.hex};
        border-radius: 50%;
        padding: 0.5rem;
        cursor: pointer;
    `}
`;

export const WaveBackground = styled.div`
    ${({ theme }) => css`
        position: absolute;
        justify-content: center;
        overflow: hidden;
        display: none;
        height: 25rem;
        width: 100%;
        background-color: ${theme.colors.secondary.tints[0].hex};
        z-index: -2;

        @media ${theme.breakpoints.medium.media} {
            top: -1rem;
            display: flex;
        }

        @media ${theme.breakpoints.large.media} {
            top: 7rem;
        }
    `}
`;

export const Wave = styled(Image)`
    position: absolute;
    height: 100%;
`;

export const Text = styled.p`
    color: white;
    max-width: 26.5rem;
`;

export const Container = styled.div<{ $isVisible: boolean }>`
    ${({ $isVisible, theme }) => css`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: ${!$isVisible ? 'hidden' : 'visible'};
        color: ${theme.colors.tertiary.hex};
        border-radius: 0.5rem;
        max-width: 68.5rem;
        margin: 0 auto;
        transform: translateZ(0);

        ${ArrowIcon} {
            color: ${theme.colors.tertiary.hex};
        }
    `}
`;

export const Inner = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 7rem 1.1rem 1.1rem;
        text-align: center;
        background: ${theme.modules.virtualTour.overlay(0, 70, 0.9)};
        z-index: 1;

        @media ${theme.breakpoints.medium.media} {
            text-align: left;
            padding: 8% 2.5rem 2.5rem;
            background: ${theme.modules.virtualTour.overlay()};
            display: unset;
            flex-direction: unset;
        }

        ${ButtonContainer} {
            margin: 0 auto;
            cursor: pointer;
        }

        ${ButtonContainer} {
            @media ${theme.breakpoints.medium.media} {
                margin: unset;
            }
        }
    `}
`;

export const CopyContainer = styled.div<{ $fileSize?: string }>`
    ${({ $fileSize, theme }) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        ${$fileSize &&
        css`
            ${Text}:last-of-type {
                margin-top: 0;
            }
        `}

        @media ${theme.breakpoints.medium.media} {
            align-items: flex-start;
        }
    `}
`;

export const Title = styled.span`
    font-size: 1.875rem;
    line-height: 1.2;
    padding: 1rem 0 2rem;
`;

export const Icon = styled.svg`
    width: 4.5rem;
    height: 4.5rem;
    color: white;
`;

export const Heading = styled.h2`
    ${({ theme }) => css`
        font-family: ${theme.fonts.secondary};
        font-weight: 300;
        color: ${theme.colors.accents[3].hex};

        @media ${theme.breakpoints.large.media} {
            font-size: 2.5rem;
        }
    `}
`;
