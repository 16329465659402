import React from 'react';

import { StyledBox } from './Box.styled';

export type SpacingValue = Record<string, number> | number;

export interface PaddingProps {
    top?: SpacingValue;
    right?: SpacingValue;
    bottom?: SpacingValue;
    left?: SpacingValue;
    x?: SpacingValue;
    y?: SpacingValue;
}

export interface Props extends React.HTMLAttributes<HTMLElement> {
    id?: string;
    p?: SpacingValue | PaddingProps;
    padding?: SpacingValue | PaddingProps;
}

const Box: React.FC<Props> = props => {
    const padding = props.p || props.padding;

    if (padding) {
        return <StyledBox id={props.id} $padding={padding} children={props.children} />;
    }

    return <div children={props.children} />;
};

export default Box;
