import React, { useState } from 'react';
import Image from 'next/image';
import { useSpring } from '@react-spring/web';
import { Cross } from '@solent-university/solent-icons';

import ArrowButton, { ArrowButtonProps } from 'Elements/Button/Arrow';

import Grid, { Col } from 'Components/Grid';

import orientationIcon from 'Public/icons/png/mob-orientation-icon.png';
import wavepng from 'Public/images/virtualTour/wave-virtual-tour.png';
import solentMap from 'Public/images/virtualTour/solent_map.jpg';

import {
    Wrapper,
    Container,
    Embed,
    EmbedWrapper,
    Background,
    Inner,
    Text,
    Heading,
    CopyContainer,
    WaveBackground,
    Wave,
    CloseIcon,
    Button,
    OrientationIcon
} from './VirtualTour.styled';

export interface Props {
    button: ArrowButtonProps;
    heading?: string;
    headingType?: 'h2' | 'h3';
    text: string;
    fileSize?: string;
    embed: {
        src: string;
        title: string;
    };
}

const VirtualTour: React.FC<Props> = ({
    embed,
    heading,
    headingType = 'h2',
    button,
    fileSize,
    text
}) => {
    const [isVisible, setIsVisible] = useState(false);

    const EmbedSpring = useSpring({
        transform: !isVisible ? 'scaleY(0.8)' : 'scaleY(1)'
    });

    return (
        <Wrapper>
            <Grid>
                <Col>
                    <Container $isVisible={isVisible}>
                        {isVisible ? (
                            <EmbedWrapper style={EmbedSpring}>
                                <Button
                                    onClick={() => setIsVisible(false)}
                                    className="m-virtualtour-close-button"
                                    aria-label="Close virtual tour embed"
                                >
                                    <CloseIcon as={Cross} />
                                </Button>
                                <Embed src={embed.src} title={embed.title} />
                            </EmbedWrapper>
                        ) : (
                            <>
                                <Inner>
                                    <CopyContainer $fileSize={fileSize}>
                                        {heading && <Heading as={headingType}>{heading}</Heading>}
                                        <Text>{text}</Text>
                                        {fileSize && <Text>{fileSize}</Text>}
                                        <OrientationIcon src={orientationIcon} loading="lazy" />
                                    </CopyContainer>
                                    <ArrowButton
                                        onClick={() => setIsVisible(true)}
                                        className="m-virtualtour-button"
                                        variant="accentOutline"
                                        {...button}
                                    />
                                </Inner>
                                <Background>
                                    <Image
                                        src={solentMap}
                                        alt="Image preview of Solent's interactive map"
                                        fill
                                        style={{ objectFit: 'cover' }}
                                    />
                                </Background>
                            </>
                        )}
                    </Container>
                </Col>
            </Grid>
            <WaveBackground>
                <Wave src={wavepng} loading="lazy" />
            </WaveBackground>
        </Wrapper>
    );
};

export default VirtualTour;
