import React from 'react';
import { ArrowLinkProps } from 'Elements/Link/Arrow';

import { Wrapper, StyledIcon, Content, Heading, Text, Link, HeadingContainer } from './Info.styled';

export interface Props {
    icon: React.ElementType;
    heading: string;
    headingType?: 'h2' | 'h3';
    text: string;
    link: ArrowLinkProps;
}

const Info: React.FC<Props> = ({ icon, heading, text, link, headingType = 'h2' }) => {
    return (
        <Wrapper>
            <div>
                <StyledIcon as={icon} />
            </div>
            <Content>
                <HeadingContainer>
                    <Heading as={headingType} $headingType={headingType}>
                        {heading}
                    </Heading>
                </HeadingContainer>
                <Text>{text}</Text>
                <Link iconOutline {...link} />
            </Content>
        </Wrapper>
    );
};

export default Info;
