import React, { useState, useEffect, useCallback } from 'react';
import NextImage from 'next/image';

import ArrowLink, { ArrowLinkProps } from 'Elements/Link/Arrow';

import { ItemsList, ItemsGrid, Item, LinkBlock, ImageContainer } from './GridNavigation.styled';

export interface Props {
    className?: string;
    items: (ArrowLinkProps & {
        id: string;
        image?: {
            src: string;
        };
        backgroundColour?: string;
    })[];
}

const GridNavigation: React.FC<Props> = props => {
    const { items, className } = props;

    const orderItemsForMobile = useCallback(() => {
        const halfOfItems = Math.ceil(items.length / 2);
        const mobileItems: Props['items'] = [];

        for (let index = 0; index < halfOfItems; index++) {
            mobileItems.push(items[index]);

            if (items[index + halfOfItems]) {
                mobileItems.push(items[index + halfOfItems]);
            }
        }

        return mobileItems;
    }, [items]);

    const [mobileItems, setMobileItems] = useState(orderItemsForMobile());

    useEffect(() => {
        setMobileItems(orderItemsForMobile());
    }, [items]);

    return (
        <div className={className}>
            <ItemsList>
                {mobileItems.map(({ id, image, variant, backgroundColour, ...rest }, index) => (
                    <Item key={id}>
                        <LinkBlock
                            $hasImage={!!image}
                            $row={index}
                            style={
                                {
                                    '--grid-block-colour': backgroundColour
                                } as React.CSSProperties
                            }
                        >
                            <ArrowLink
                                {...rest}
                                iconOutline
                                variant={variant ? variant : 'secondary'}
                            />
                        </LinkBlock>
                        {image && (
                            <ImageContainer>
                                <NextImage {...image} alt="" fill style={{ objectFit: 'cover' }} />
                            </ImageContainer>
                        )}
                    </Item>
                ))}
            </ItemsList>
            <ItemsGrid>
                {items.map(({ id, image, variant, backgroundColour, ...rest }, index) => (
                    <Item key={id}>
                        <LinkBlock
                            $hasImage={!!image}
                            $row={Math.ceil((index + 1) / 3)}
                            style={
                                {
                                    '--grid-block-colour': backgroundColour
                                } as React.CSSProperties
                            }
                        >
                            <ArrowLink
                                {...rest}
                                iconOutline
                                variant={variant ? variant : 'secondary'}
                            />
                        </LinkBlock>
                        {image && (
                            <ImageContainer>
                                <NextImage {...image} alt="" fill style={{ objectFit: 'cover' }} />
                            </ImageContainer>
                        )}
                    </Item>
                ))}
            </ItemsGrid>
        </div>
    );
};

export default GridNavigation;
