import styled, { css } from 'styled-components';
import Icon from 'Elements/Icon';
import BaseArrowLink from 'Elements/Link/Arrow';

export const Wrapper = styled.article`
    ${({ theme }) => css`
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 2.5rem;
        padding: 0 3.5rem;

        @media ${theme.breakpoints.medium.media} {
            display: block;
            padding: 0;
            margin-bottom: 0;
        }

        @media ${theme.breakpoints.large.media} {
            display: flex;
            flex-direction: row;
        }
    `}
`;

export const StyledIcon = styled(Icon)`
    ${({ theme }) => css`
        height: 2.5rem;
        width: 2.5rem;
        color: ${theme.colors.primary.hex};
        margin-bottom: 0.5rem;

        @media ${theme.breakpoints.medium.media} {
            margin-bottom: 0.5rem;
            margin-right: 0.5rem;
            height: 3rem;
            width: 3rem;
            float: left;
            margin-top: 0.4rem;
        }

        @media ${theme.breakpoints.large.media} {
            margin: 0.5rem 1rem;
            height: 3.5rem;
            width: 3.5rem;
        }
    `}
`;

export const Content = styled.div`
    ${({ theme }) => css`
        display: flex;
        height: 100%;
        flex-direction: column;

        @media ${theme.breakpoints.medium.media} {
            padding-bottom: 1.75rem;
            display: block;
        }

        @media ${theme.breakpoints.large.media} {
            padding-bottom: 0;
            display: flex;
            flex-direction: column;
        }
    `}
`;

export const Heading = styled.h3<{ $headingType: string }>`
    ${({ theme, $headingType }) => css`
        margin-top: 0;
        color: ${theme.colors.primary.hex};
        font-weight: 700;
        line-height: 1.2;
        margin-bottom: 1.5rem;

        @media ${theme.breakpoints.medium.media} {
            max-width: 14rem;
        }

        @media ${theme.breakpoints.large.media} {
            font-size: 2.25rem;
            max-width: 16rem;
        }

        ${$headingType === 'h3' &&
        css`
            margin-bottom: 1rem;
            font-size: 1.375rem;

            @media ${theme.breakpoints.large.media} {
                font-size: 1.75rem;
            }
        `}
    `}
`;

export const Text = styled.p`
    margin-top: 0rem;

    @media ${({ theme }) => theme.breakpoints.medium.media} {
        max-width: 19rem;
    }
`;

export const Link = styled(BaseArrowLink)`
    ${({ theme }) => css`
        @media ${theme.breakpoints.medium.media} {
            position: absolute;
            bottom: 0;
        }

        @media ${theme.breakpoints.large.media} {
            position: relative;
            margin-top: auto;
        }
    `}
`;

export const HeadingContainer = styled.div`
    display: flex;
    vertical-align: center;
`;
