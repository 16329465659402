import styled, { css } from 'styled-components';

export const List = styled.ul`
    ${({ theme }) => css`
        display: grid;
        list-style: none;
        padding: 0 0.25rem;

        @media ${theme.breakpoints.medium.media} {
            grid-gap: 1.5rem;
            grid-template-columns: repeat(3, 1fr);
        }

        @media ${theme.breakpoints.large.media} {
            grid-template-columns: repeat(3, 1fr);
        }
    `}
`;

export const ListItem = styled.li`
    height: 100%;
`;
