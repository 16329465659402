import styled, { css } from 'styled-components';

const Items = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

export const ItemsList = styled(Items)`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1rem;

    @media ${({ theme }) => theme.breakpoints.medium.media} {
        display: none;
    }
`;

export const ItemsGrid = styled(Items)`
    display: none;

    @media ${({ theme }) => theme.breakpoints.medium.media} {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 1.5rem;
        row-gap: 1.5rem;
    }
`;

export const Item = styled.li`
    position: relative;
`;

export const LinkBlock = styled.div<{ $hasImage: boolean; $row: number }>`
    ${({ theme, $hasImage, $row }) => css`
        position: relative;
        text-align: center;
        border-radius: ${$hasImage ? '0.5rem 0.5rem 0 0' : '0.5rem'};
        background: var(
            --grid-block-colour,
            ${$row % 2 === 0 ? theme.colors.tertiary.hex : theme.colors.primary.hex}
        );
        padding: 0.5rem;

        @media ${theme.breakpoints.large.media} {
            padding: 1.5rem 0.5rem;
        }
    `}
`;

export const ImageContainer = styled.div`
    ${({ theme }) => css`
        height: 12.5rem;
        width: 100%;
        position: relative;
        border-radius: 0 0 0.5rem 0.5rem;
        overflow: hidden;

        @media ${theme.breakpoints.medium.media} {
            height: 9rem;
        }

        @media ${theme.breakpoints.large.media} {
            height: 17.75rem;
        }
    `}
`;
