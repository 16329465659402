import React from 'react';
import Grid, { Col } from 'Components/Grid';

import Info, { InfoProps } from './subcomponents/Info';

import { List, ListItem } from './InformationGrid.styled';

export interface Props {
    items: InfoProps[];
}

const InformationGrid: React.FC<Props> = ({ items }) => {
    return (
        <Grid>
            <Col>
                <List>
                    {items.map((item, index) => (
                        <ListItem key={index}>
                            <Info {...item} />
                        </ListItem>
                    ))}
                </List>
            </Col>
        </Grid>
    );
};

export default InformationGrid;
